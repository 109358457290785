.navigator {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    z-index: 1000;
}

nav {
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

nav img {
    width: 100px;
}

nav ul {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

nav li {
    padding: 0 10px;
    font-size: 18px;
    font-weight: 600;
    color: #6311e6;
}

nav li a {
    display: flex;
    flex-direction: row;
    align-items: center;
}

nav li a img {
    margin-right: 5px;
    width: 22px;
}

.logout img {
    width: 30px;
}

@media (max-width: 1200px) {
    nav {
        justify-content: space-evenly;
    }
    
    nav ul {
        width: 60%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }
}

@media (max-width: 767px) {
    nav {
        align-items: end;
    }

    nav li {
        padding: 5px;
    }
    
    .logo img {
        width: 80px;
    }

    .logout img {
        width: 35px;
    }

    nav li a img {
        display: none;
    }

    nav li a span {
        text-align: center;
        font-size: 12px;
    }

}
