.card-form {
    margin: 0 auto;
    width: 60%;
}

.title-template {
    margin: 0 auto;
    margin-bottom: 30px;
    width: 60%;
}


.field-animes {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.field-animes input {
    margin: 0;
    width: 20px !important;
    height: 20px;
}

.field-animes span {
    margin-left: 10px;
    color: #480CA8;
    font-size: 18px;
}

.img-icon {
    margin: 20px auto;
    min-width: 36px;
    min-height: 36px;
}




@media (max-width: 767px) {
    .card-form {
        width: 100%;
    }

    .title-template {
        width: 100%;
    }

}