@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Barlow:wght@400;500;600&family=Montserrat:wght@300;400;500;600;700;800&display=swap');

* {
  font-family:'Montserrat';
  box-sizing: border-box; 
  margin: 0;
  padding: 0;
  list-style: none;
}

body {
  background-color: #eeeeee;
}

a {
  text-decoration: none;
}

a:hover {
  color: inherit;
}

[data-amplify-authenticator] {
  width: 100%;
  height: 100vh !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}


