main {
    margin-top: 200px;
}

.page-bookmark span, b {
    color: #707070;
}

.bookmark-form {
    margin-bottom: 100px;
}

.sub-title {
    margin-left: 10px;
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    color: #480CA8;
} 

.new-page-card {
    padding: 40px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    background-color: #fff;
}

.new-page-card form {
    display: flex;
    flex-direction: column;
}

.new-page-card label {
    margin-left: 10px;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 17px;
    color: #480CA8;
}

.new-page-card input, 
.new-page-card select,
.new-page-card textarea {
    width: 100%;
    padding: 12px;
    margin-bottom: 25px;
    border: 1px solid #480CA8;
    border-radius: 10px;
    outline: none;
}

.card-perfil {
    margin-bottom: 60px;
}

.new-page-card button {
    width: 100%;
    background-color: #d7d7d7;
    padding: 12px 5px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 600;
}

.new-page-card svg {
    margin-right: 15px;
}

.btn-add-link {
    margin-top: 15px;
}

.btn-remove-link {
    margin: 15px 0 50px 0;
}

.card-preview {
    height: 1368px;
    padding: 40px;
    border-radius: 15px;  
    background-color: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.card-preview h2 {
    text-align: center;
    margin-bottom: 50px;
    font-size: 25px;
    font-weight: 700;
    color: #6200ff;
}

.card-preview iframe {
    width: 100%;
    height: 90%;
}


.btn-save {
    margin: 50px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.btn-save button {
    width: 40%;
    padding: 15px 5px;
    background-color: #480CA8;
    border-radius: 15px;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
}


@media (max-width: 767px) {
    main {
        margin-top: 160px;
    }

    .page-bookmark {
        margin-bottom: 40px;
    }

    .page-bookmark span, b {
        font-size: 12px;
    }

    .card-preview {
        margin-top: 45px;
    }

    .title-preview {
        margin-top: 50px;
    }

    .btn-save button {
        width: 75%;
    }

    .sub-title {
        font-size: 23px;
    }
}