.table-list {
    width: 100%;
    border-radius: 15px;
    margin-bottom: 50px;
    background-color: #fff;
}

.table-list thead {
    border-bottom: 3px solid #fff;
}

.table-list th {
    padding: 20px 0;
    color: #575454;
    text-transform: uppercase;  
    text-align: center;
}

.table-list tbody button {
    padding: 10px 20px;
    border-radius: 10px;
    background-color: #390988;
    color: #fff;
    font-weight: 600;
}

.table-list tbody tr {
    border-top: 1px solid #fff;
}

.table-list td {
    vertical-align: middle;
    padding: 15px 0;
    text-align: center;
    color: #575454;
    font-size: 18px;
    font-weight: 500;
}

.table-list td img {
    padding: 5px;
    margin: 0 auto;
    width: 70px;
}


.table-striped>tbody>tr:nth-of-type(odd)>* {
    background-color: #39098829;
    color: #575454 !important;
}

.card-template {
    margin: 0 auto;
    height: 900px;
    margin-top: 100px;
    margin-bottom: 50px;
}

@media (max-width: 767px) {
    .table-list th {
        font-size: 12px;
    }

    .table-list td {
        font-size: 12px;
    }

    .table-list tbody button {
        margin-right: 5px;
    }
}