
.title {
    height: 110px;
    position: relative;
    margin: 0 0 100px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.title h1 {
    font-size: 40px;
    font-weight: 700;
    color: #480CA8;
}

.title a {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title a span {
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: 800;
    color: #480CA8;
}

.btn-new {
    padding: 30px;
    background-color: #FFD702;
    border-radius: 15px;
}

.btn-new img {
    width: 30px;
}

.display-my-pages {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.card-page {
    background-color: #FFF;
    width: 49%;
    height: 900px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    border-radius: 15px;
}

.display-card-page {
    height: 100%;
    padding: 40px;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    border-radius: 15px;
}

.display-card-page iframe {
    width: 100%;
    height: 85%;
}

.title-card-page {
    height: 145px;
    position: relative;
}

.title-card-page h2 {
    text-align: center;
    font-size: 25px;
    font-weight: 700;
    color: #480CA8;
}

.title-card-page button {
    width: 100px;
    position: absolute;
    padding: 12px 0;
    right: 0;
    background-color: #480CA8;
    border-radius: 7px;
    color: #fff;
    font-weight: 700;
}

.btn-view {
    top: 55px;
}

.btn-edit {
    top: 0;
}

@media (max-width: 767px) {
    .title {
        height: 55px;
        margin: 0 0 35px 0;
    }
    .title h1 {
        font-size: 23px;
    }

    .title span {
        display: none;
    }

    .btn-new {
        padding: 15px 15px;
        border-radius: 15px;
    }

    .btn-new img {
        width: 20px;
        margin: 0;
    }

    .display-my-pages {
        flex-direction: column;
    }

    .card-page {
        width: 100%;
    }

    .title-card-page button {
        width: 55px;
        font-size: 12px;
    }
}